<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers" showFilter :filterData="filterForm">
        <template v-slot:no_="{ item }">
          <a href="javascript:;" @click="getCustomers(item.no_)">{{ item.no_ }}</a>
        </template>

        <template v-slot:starting_date="{ item }">
          <span>{{ appDateFormat(item.starting_date) }}</span>
        </template>

        <template v-slot:ending_date="{ item }">
          <span>{{ appDateFormat(item.ending_date) }}</span>
        </template>

        <template v-slot:last_sync_date="{ item }">
          <span>{{ appDatetimeFormat(item.last_sync_date) }}</span>
        </template>

        

        <template #filter-body>
          <v-autocomplete v-model="filterForm.status_code" label="Status Code" :items="$store.state.master.campaign_status_code" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.sub_status" label="Sub Status" :items="$store.state.master.campaign_sub_status" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
          <v-autocomplete v-model="filterForm.global_dimension_1_code" label="Area Code" :items="$store.state.master.area" item-value="area_code" item-text="area_code" class="mb-2" hide-details multiple deletable-chips chips clear-icon clearable small-chips hide-selected outlined></v-autocomplete>
        </template>
      </app-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card max-width="420" :loading="loading2">
        <v-toolbar elevation="0">
          <v-toolbar-title>Select Customer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-select clearable hide-selected :items="customersListItems" item-text="text" item-value="value" v-model="selectedCutomerNo" hide-details label="Select Customer"></v-select>
          <a href="javascript:;" @click="getCustomers(true)">Reload</a>
        </v-card-text>
        <v-card-actions>
          <v-btn block color="primary" @click="getDetail()" :disabled="disabledGotoDetail">Go to detail</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: null,
      loading2: null,
      datatable: {
        uri: "campaign/data",
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Starting Date", value: "starting_date", class: "text-no-wrap" },
          { text: "Ending Date", value: "ending_date", class: "text-no-wrap" },
          { text: "Area Code", value: "global_dimension_1_code", class: "text-no-wrap" },
          { text: "City", value: "city", class: "text-no-wrap" },
          { text: "Status", value: "status_code", class: "text-no-wrap" },
          { text: "Sub Status", value: "sub_status", class: "text-no-wrap" },
          { text: "Last Sync Date", value: "last_sync_date", class: "text-no-wrap" },
        ],
      },

      dialog: null,
      selectedCampaignNo: "",
      selectedCutomerNo: "",
      customers: [],
      customersListItems: [],

      filterForm: {
        status_code: [],
        sub_status: [],
        global_dimension_1_code: [],
      },
    };
  },
  computed:{
    disabledGotoDetail(){
      if (this.selectedCutomerNo.length > 0) return false;
      return true;
    }
  },
  methods: {
    getCustomers(campaign_no_) {

      this.selectedCampaignNo = campaign_no_

      this.dialog = true
      this.selectedCutomerNo = {}
      this.customers = []
      this.customersListItems = []

      this.loading2 = true;
      var params = {
        campaign_no_: this.selectedCampaignNo,
      };
      this.$axios
        .get("campaign/customer-list", {
          params: params,
        })
        .then((res) => {
          this.loading2 = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.customers = resData.data;

            if (this.customers.length == 1) {
              this.selectedCutomerNo = this.customers[0]
            }

            for (var i = 0; i < this.customers.length; i++) {
              this.customersListItems.push({
                text: this.customers[i].name + ' (' + this.customers[i].no_ + ')',
                value: this.customers[i].no_
              });
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading2 = false;
          this.axiosErrorHandler(error);
        });
    },
    getDetail() {
      // console.log(this.selectedCutomerNo)

      if (this.selectedCutomerNo.length > 0){
        this.redirect('Sales.Campaign.Detail', { no_: this.selectedCampaignNo, customer_no_: this.selectedCutomerNo });
      } else {
        this.showAlert("error", "Select customer!");
      }
    },
  },
  mounted() { },
};
</script>
